import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton, Typography, Collapse } from '@material-ui/core';
import useAddCourseHook from './useAddCourseHook';
import { withStyles } from '@material-ui/core/styles';
import styles from './add-course.style';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete, DraggableTile } from '@orijinworks/frontend-commons';

const AddCourse = ({ classes, formData, handleCourseChange }) => {
  const {
    addCourseClick,
    showSearchSection,
    hideSearchSection,
    searchValue,
    handleSearchChange,
    isLoading,
    options,
    addSelectedCourse,
    draggableItems
  } = useAddCourseHook(formData, handleCourseChange);

  return (
    <div>
      <Typography
        variant="h4"
        color="primary"
        className={classes.title}
        component="h1"
        data-testid="program-courses-title"
      >
        Program Courses
      </Typography>

      <Typography variant="subtitle2" color="textSecondary" className={classes.fieldLabel}>
        Course List
      </Typography>

      <Typography variant="caption" color="textSecondary" className={classes.characterLimit}>
        A program must contain at least one course to be saved. Courses will display in the program catalog in the order
        listed below.
      </Typography>

      {draggableItems.length > 0 && (
        <DraggableTile
          itemsList={draggableItems}
        />
      )}

      <Collapse in={showSearchSection} timeout={300}>
        <Box className={classes.searchCoursesSection}>
          <Box paddingTop="24px" paddingBottom="12px">
            <Box display="flex" gap="12px">
              <FormControl sx={{ paddingBottom: '12px' }} fullWidth>
                <Autocomplete
                  id="search-courses-select"
                  label="Search ID Course"
                  fullWidth
                  freeSolo={true}
                  error={false}
                  inputValue={searchValue}
                  onChange={(event, value) => handleSearchChange(value, 'select')}
                  onInputChange={(event, newValue) => handleSearchChange(newValue, 'input')}
                  options={options}
                  disabled={isLoading}
                  loading={isLoading}
                  open={isLoading || options.length > 0}
                  testId="search-courses-input"
                  getOptionLabel={(option) => option.label || ''}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">{option.label}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {option.externalId}
                        </Typography>
                      </Box>
                    </li>
                  )}
                  filterOptions={(options) => options}
                />
              </FormControl>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="92px"
                height="40px"
                gap="12px"
              >
                <IconButton
                  id="add-course-to-list"
                  className={classes.addCourseRoundButton}
                  disabled={!searchValue?.trim()}
                  onClick={addSelectedCourse}
                >
                  <AddIcon fontSize="small" className={classes.addIcon} />
                </IconButton>
                <IconButton
                  aria-label="Cancel"
                  onClick={hideSearchSection}
                  disabled={false}
                  data-testid="cancel-button"
                >
                  <CloseIcon className={classes.cancelCourseRoundButton} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>

      <div>
        <Button
          id="add-course-btn"
          data-testid="add-course-btn"
          variant="outlined"
          aria-label="Add Course"
          className={classes.addCourseButton}
          startIcon={<AddIcon />}
          onClick={addCourseClick}
          disabled={showSearchSection}
        >
          Add Course
        </Button>
      </div>
    </div>
  );
};
export default withStyles(styles)(AddCourse);