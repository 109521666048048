import React from 'react';
import styles from './schedule-accordian.style';
import {
  Box,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Avatar,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { DATE_FORMAT } from '../../../../../../constants';
import { Stepper, Button, TextField, Modal, Schedule } from '@orijinworks/frontend-commons';

import moment from 'moment';
import useScheduleAccordianHook from './useScheduleAccordianHook';
import { VOICE_CALL } from '../../../../constants/voice-call-constants';

const ScheduleAccordian = ({ classes, scheduleData, onDeleteSchedule, maxMinutesLimit }) => {
  const {
    scheduleDataObj,
    isActionButtonsDisabled,
    expanded,
    showDeleteModal,
    setShowDeleteModal,
    editMode,
    message,
    messageType,
    showSnackBar,
    setShowSnackBar,
    handleExpand,
    handleEditSchedule,
    handleSaveChanges,
    handleCancelEdit,
    handleDeleteSchedule,
    handleScheduleDataChange,
    setIsScheduleFormValid,
    isScheduleFormValid,
    scheduleRef,
  } = useScheduleAccordianHook({ scheduleData, onDeleteSchedule });

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Accordion className={classes.Accordian} expanded={expanded} onChange={handleExpand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.ExpandIcon} id="expand-icon" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ content: classes.Summary }}
          >
            <Box display="flex" flexDirection="column" width="100%" padding={"4px"}>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={classes.ScheduleName}>{scheduleDataObj.scheduleName}</Typography>
                {expanded && !editMode && (
                  <Button
                    id="edit-schedule-button"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    className={classes.EditButton}
                    onClick={handleEditSchedule}
                  >
                    Edit Schedule
                  </Button>
                )}
              </Box>
              <Typography className={classes.CreationDate}>
                Created {moment(scheduleDataObj.createdDate).format(DATE_FORMAT.MM_DD_YYYY)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.Details}>
            {editMode && (
              <Box className={classes.DetailItem}>
                <Box className={classes.DetailItemContainer}>
                  <Typography className={classes.DetailTitle}>Schedule Name</Typography>
                  <TextField
                    id="schedule-name"
                    required
                    error={scheduleDataObj.scheduleName === ''}
                    onChange={(event) => {
                      handleScheduleDataChange(event, 'scheduleName');
                    }}
                    value={scheduleDataObj.scheduleName}
                  />
                </Box>
              </Box>
            )}
            <Box className={classes.DetailItem}>
              <Box className={classes.DetailItemContainer}>
                <Typography className={classes.DetailTitle}>Max minutes per call</Typography>
                {editMode && (
                  <Stepper
                    id="max-minutes-per-call-stepper"
                    value={scheduleDataObj.maxMinutesPerCall}
                    minValue={VOICE_CALL.MIN_NO_OF_MINUTES_PER_CALL}
                    maxValue={VOICE_CALL.MAX_NO_OF_MINUTES_PER_CALL}
                    step={1}
                    onChange={(event) => handleScheduleDataChange(event, 'maxMinutesPerCall')}
                  />
                )}
              </Box>
              {!editMode && <Typography id="minutes-per-call">{scheduleDataObj.maxMinutesPerCall} minutes</Typography>}
            </Box>
            <Box className={classes.DetailItem}>
              <Box className={classes.DetailItemContainer}>
                <Typography className={classes.DetailTitle}>Max minutes per day</Typography>
                {editMode && (
                  <Stepper
                    id="max-minutes-per-day-stepper"
                    value={scheduleDataObj.maxMinutesPerDay}
                    name="maxMinutesPerDay"
                    minValue={VOICE_CALL.MIN_NO_OF_MINUTES_PER_DAY_PER_LEARNER}
                    maxValue={maxMinutesLimit}
                    step={1}
                    onChange={(event) => handleScheduleDataChange(event, 'maxMinutesPerDay')}
                  />
                )}
              </Box>
              {!editMode && <Typography id="minutes-per-day">{scheduleDataObj.maxMinutesPerDay} minutes</Typography>}

              <Box display='flex' flexDirection='column' justifyContent='flex-start' paddingTop={2}>
                <Typography className={classes.CallScheduleHeading}>Call Schedule</Typography>
                <Box id='schedule-editor'>
                  <Schedule key={'schedule-editor'} setIsFormValid={setIsScheduleFormValid} schedule={scheduleDataObj.callSchedule ? scheduleDataObj.callSchedule : []} editable={editMode} ref={scheduleRef} maxNoSlotsInDay={VOICE_CALL.MAX_NO_SLOTS_IN_DAY} minimumSlotDuration={VOICE_CALL.MIN_SLOT_DURATION} />
                </Box>
              </Box>
            </Box>
            {editMode && (
              <Box width="100%">
                <Box className={classes.ButtonContainer}>
                  <Button
                    id="save-changes-button"
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    className={classes.SaveButton}
                    disabled={isActionButtonsDisabled || !isScheduleFormValid}
                  >
                    Save changes
                  </Button>
                  <Button id="cancel-edit-button" variant="outlined" color="secondary" onClick={handleCancelEdit} disabled={isActionButtonsDisabled}>
                    Cancel
                  </Button>
                </Box>
                <Divider className={classes.Divider} />
                <Box className={classes.DeleteContainer}>
                  <Typography>
                    <b>Delete group schedule?</b>
                  </Typography>
                  <Button
                    id="delete-modal-button"
                    variant="outlined"
                    color="destructive"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            )}
          </AccordionDetails>
          {showDeleteModal && (
            <Modal id='delete-schedule-modal'
              Icon={
                <Avatar className={classes.DeleteAvatar}>
                  <DeleteIcon className={classes.ModalIcon} />
                </Avatar>
              }
              className={classes.Modal}
              open={showDeleteModal}
              showCrossIcon={true}
              actionBtnText="Delete"
              cancelBtnText="Cancel"
              showFooter={true}
              onContinue={handleDeleteSchedule}
              onClose={() => {
                setShowDeleteModal(false);
              }}
              continueButtonProps={{ color: 'destructive' }}
              styles={{
                mainWrapperStyles: {
                  height: 'auto',
                  width: '25%',
                },
              }}
            >
              <Typography variant="h6">Delete Schedule</Typography>

              <Typography>
                Are you sure you would like to delete this schedule? Specific learners will be moved to a default schedule.
              </Typography>
            </Modal>
          )}
        </Accordion>
      </Box>


      <Snackbar open={showSnackBar} autoHideDuration={3000} onClose={() => { setShowSnackBar(false) }}>
        <MuiAlert elevation={6} variant="filled" severity={messageType === 'success' ? 'success':'error'}>
          {message} 
        </MuiAlert>
      </Snackbar>
    </>

  );
};

export default withStyles(styles)(ScheduleAccordian);
