export const AUTH_CONSTANTS = {
  /**
   * List of Keycloak Roles.
   */
  KEYCLOAK_ROLES: {
    LEARNER: 'apds_connected_corrections_role',
    SUPER_ADMIN: 'apds_horizon_super_admin_role',
    ADMIN: 'apds_horizon_role',
    FACILITY_ADMIN: 'apds_horizon_facility_admin_role',
    PROGRAMMING_STAFF: 'apds_horizon_programming_staff_role',
    ATTORNEY: 'apds_attorney_role',
    TEACHER: 'apds_teacher_role',
    FNF: 'apds_friendsandfamily_role',
  },

  /**
   * List of Auth0 Roles.
   */
  AUTH0_ROLES: {
    ADMIN: 'admin',
    SUPER_ADMIN: 'super_admin',
    FACILITY_ADMIN: 'facility_admin',
    PROGRAMMING_STAFF: 'programming_staff',
    ATTORNEY: 'attorney',
    TEACHER: 'teacher',
    LEARNER: 'learner',
    FNF: 'fnf',
  },

  /**
   * List of Auth Guards actions.
   */
  AUTH_GUARDS_ACTION: {
    BLOCK_ACCESS: 'BLOCK_ACCESS',
  },

  /**
   * List of Auth Guards.
   */
  AUTH_GUARDS_LIST: {
    ADMIN_PORTAL_ACCESS: 'ADMIN_PORTAL_ACCESS_GUARD',
  },

  /**
   * List of Auth Guards results.
   */
  AUTH_GUARDS_RESULT: {
    GRANT_ACCESS: 'GRANT_ACCESS',
    DENY_ACCESS: 'DENY_ACCESS',
  },

  /**
   * Auth session correlation key. Used in storing the auth session correlation in localStorage.
   */
  AUTH_SESSION_CORRELATION_KEY: 'auth_session_correlation',
};
