import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@orijinworks/frontend-commons';

import useAddProgramHook from './useAddProgramHook';
import styles from './add-program.style';
import { ProgramDetails, ImageUpload, ProgramMetadata, FormActions } from './components';
import DescriptionField from './components/description-field/description-field';
import AddCourse from './components/add-course/add-course';

const AddProgram = ({ classes, handleAddNew, handleBackToList, handleEditProgram, isEditingMode, programData }) => {
  const {
    formData,
    alertState,
    isFormValid,
    handleChange,
    handleImageUpload,
    handleImageDelete,
    handleAlertClose,
    handleSubmit,
    getImageUrl,
    handleCourseChange,
  } = useAddProgramHook(handleBackToList, handleAddNew, handleEditProgram, isEditingMode, programData);

  return (
    <Box className={classes.root} data-testid="add-program-root">
      <Box display="flex" alignItems="center" className={classes.backButton}>
        <IconButton
          data-testid="arrow-back-button"
          className={classes.arrowBackButton}
          aria-label="Back"
          onClick={() => handleBackToList()}
        >
          <ArrowBackIcon className={classes.arrowBackIcon} />
        </IconButton>
        <Typography variant="h4" className={classes.addProgramtext}>{isEditingMode ? 'Edit Program' : 'Add New Program'}</Typography>
      </Box>

      <Box className={classes.fieldGroup}>
          <ProgramDetails classes={classes} formData={formData} handleChange={handleChange} isEditingMode={isEditingMode} />

          <ImageUpload
            classes={classes}
            formData={formData}
            handleImageUpload={handleImageUpload}
            handleImageDelete={handleImageDelete}
            getImageUrl={getImageUrl}
          />

          <DescriptionField 
            classes={classes}
            formData={formData}
            handleChange={handleChange}
            title="Short Program Description"
            characterLimit={300}
            fieldNameEn="shortDescriptionEn"
            fieldNameEs="shortDescriptionEs"
          />
          <DescriptionField 
            classes={classes}
            formData={formData}
            handleChange={handleChange}
            title="Long Program Description"
            characterLimit={1000}
            fieldNameEn="longDescriptionEn"
            fieldNameEs="longDescriptionEs"
            multiline={true}
            rows={8}
          />
          <ProgramMetadata classes={classes} formData={formData} handleChange={handleChange} />

        <AddCourse formData={formData} handleCourseChange={handleCourseChange} />

        <Box py={4}>
          <Divider />
        </Box>

          <FormActions 
            classes={classes} 
            isFormValid={isFormValid} 
            handleBackToList={handleBackToList}
            handleSubmit={handleSubmit}
          />
        </Box>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleAlertClose} severity={alertState.severity}>
          {alertState.message}
        </MuiAlert>
      </Snackbar>

      <Dialog open={formData.showDeleteDialog} onClose={() => handleImageDelete(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <strong>Remove current thumbnail image?</strong>
            <IconButton size="small" onClick={() => handleImageDelete(false)} data-testid="dialog-close-button">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove the current image? This action cannot be undone. You will need to upload another
            image as the thumbnail.
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => handleImageDelete(true)}
            data-testid="remove-image-button"
          >
            Remove Image
          </Button>
          <Button 
            variant="outlined" 
            onClick={() => handleImageDelete(false)}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

AddProgram.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddNew: PropTypes.func.isRequired,
  handleBackToList: PropTypes.func.isRequired,
  handleEditProgram: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  programData: PropTypes.object.isRequired
};

export default withStyles(styles)(AddProgram);
