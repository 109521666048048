import { useState, useEffect } from 'react';
import { fetchPrograms, createProgram, fetchProgramById, updateProgram } from './services/program.service';

const useManageProgramsHook = () => {
  const [programs, setPrograms] = useState([]);
  const [programData, setProgramData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddProgram, setShowAddProgram] = useState(false);
  const [showEditProgram, setShowEditProgram] = useState(false);


  useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true);
        const response = await fetchPrograms();
        setPrograms(response?.payload?.programs || []);
      } catch (err) {
        setError('Failed to fetch programs');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getPrograms();
  }, []);

  const moveScrollToTop = () => {
    const mainContainer = document.getElementById('main-container');
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  const getProgramAndPopulateEditFields = async (e, programId) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await fetchProgramById(programId);
      setProgramData(response?.payload);
      setShowEditProgram(true);
      setShowAddProgram(true);
      moveScrollToTop();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProgram = async (programData, programId) => {

    try {
      setLoading(true);
      const response = await updateProgram(programData, programId);
      if (response?.status === 'success') {
        handleBackToList();
      } else {
        throw new Error('Invalid program data received');
      }
    } catch (err) {
      setError('Failed to create program');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleAddNew = async (programData) => {
    if (!programData) {
      setShowAddProgram(true);
      setShowEditProgram(false);
      return;
    }

    try {
      setLoading(true);
      const response = await createProgram(programData);
      const newProgram = response?.payload;
      if (newProgram) {
        setPrograms([...programs, newProgram]);
        handleBackToList();
      } else {
        throw new Error('Invalid program data received');
      }
    } catch (err) {
      setError('Failed to create program');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToList = () => {
    setShowAddProgram(false);
    setShowEditProgram(false);
    moveScrollToTop();
  };

  return {
    programs,
    programData,
    loading,
    error,
    getProgramAndPopulateEditFields,
    handleAddNew,
    handleBackToList,
    handleEditProgram,
    showAddProgram,
    showEditProgram
  };
};

export default useManageProgramsHook;