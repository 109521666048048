import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CHARACTER_LIMITS, FORM_FIELDS } from '../../../../constants/form-constants';
import styles from './program-details.style';

const ProgramDetails = ({ classes, formData, handleChange, isEditingMode }) => (
  <>
    <Typography variant="h2" className={classes.sectionTitle} data-testid="section-title">
      Program Details
    </Typography>

    <Box className={classes.fieldGroup}>
      <Typography className={classes.fieldLabel} data-testid="field-label">PROGRAM NAME</Typography>
      <Typography className={classes.characterLimit} data-testid="char-limit">Max 100 characters</Typography>

      <Box className={classes.textFieldGroup}>
        <TextField
          fullWidth
          name={FORM_FIELDS.DISPLAY_NAME_EN}
          label={<span className={classes.labelText}>Display Name (English)</span>}
          placeholder="e.g. The Master Plan"
          InputLabelProps={{ shrink: true }}
          value={formData.displayNameEn}
          onChange={handleChange}
          variant="outlined"
          className={classes.textField}
          data-testid="english-name-field"
          inputProps={{ maxLength: CHARACTER_LIMITS.DISPLAY_NAME }}
          disabled={isEditingMode}
        />
        <TextField
          fullWidth
          name={FORM_FIELDS.DISPLAY_NAME_ES}
          label={<span className={classes.labelText}>Display Name (Español)</span>}
          placeholder="e.g. El Plan Maestro"
          InputLabelProps={{ shrink: true }}
          value={formData.displayNameEs}
          onChange={handleChange}
          variant="outlined"
          className={classes.textField}
          data-testid="spanish-name-field"
          inputProps={{ maxLength: CHARACTER_LIMITS.DISPLAY_NAME }}
          disabled={isEditingMode}
        />
      </Box>
    </Box>
  </>
);

ProgramDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isEditingMode: PropTypes.bool.isRequired
};

export default withStyles(styles)(ProgramDetails);
