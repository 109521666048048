import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import styles from './call-history-details-v2.style';
import { audioPlayerUseStyles } from './call-history-details-v2.style';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
import { Box, IconButton, Grid, Button, Drawer, CircularProgress } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AudioPlayer from 'material-ui-audio-player';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { calculateDurationInMins, formatDatetime, getFullName } from '../../utils/utils';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TranscriptPdf from '../transcript-pdf/transcript-pdf';
import Transcript from '../transcript/transcript';
import moment from 'moment-timezone';
import FlaggedKeywords from '../flagged-keywords/flagged-keywords';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { DATE_FORMAT } from '../../../../constants';
import { Skeleton } from '@material-ui/lab';
const CallHistoryDetailsDrawer = (props) => {
  const {
    classes,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal,
    learnerFirstName,
    learnerLastName,
    learnerDocId,
    callState,
    contactPhoneNumber,
    contactInfo,
    joinTimeUtc,
    endTimeUtc,
    voiceCallInsights,
    recordingUrl,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
    isPrivileged,
    meetingId,
    callType,
  } = props;
  let contactInfoObj = {};
  try {
    contactInfoObj = contactInfo ? JSON.parse(contactInfo) : {};
  } catch (error) {
    contactInfoObj = {};
  }
  const hasTranscript = transcriptData && transcriptData.transcript && transcriptData.transcript.length > 0;
  const hasSummary =
    hasTranscript && transcriptSummary && transcriptSummary.main_topics && transcriptSummary.main_topics.length > 0;
  const isVRSCallToMedical = callType === VOICE_CALL.VOICE_CALL_TYPE.VRS && contactInfoObj.relationshipType === 'Medical';
  const fileName = `${moment(joinTimeUtc).format(DATE_FORMAT.YYYY_MM_DD_HHMM)}_${learnerLastName?.trim()}${learnerFirstName?.trim()}_${contactInfoObj?.lastName?.trim() ?? ''}${contactInfoObj?.firstName?.trim() ?? ''}_${callType === VOICE_CALL.VOICE_CALL_TYPE.VRS ? 'VRS' : 'Voice'}_${meetingId}`;
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const [isRecordingDownloaded, setIsRecordingDownloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (isDetailModalOpen) {
      setIsLoading(true);
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isDetailModalOpen]);

  return (
    <Drawer anchor="right" open={isDetailModalOpen} className={classes.root}>
      <Box className={classes.drawer}>
        <Box className={classes.drawerHeader}>
          <IconButton
            id="close-button"
            aria-label="close"
            className={classes.closeIcon}
            onClick={handleCloseHistoryDetailsModal}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Box>
        <Box className={classes.drawerContent}>
          <Typography variant="h1">Call Details</Typography>
          <Grid container spacing={1}>
            <Grid xs={12} className={classes.callDetailsSection}>
              {isLoading ? (
                <>
                  <Grid item xs={6}>
                    <Box className={classes.infoGroup}>
                      <Skeleton variant="text" width="80%" height={32} />
                      <Skeleton variant="text" width="40%" height={24} />
                      <Skeleton variant="text" width="60%" height={24} />
                    </Box>
                  </Grid>
                  <IconButton>
                    <Skeleton variant="circle" width={40} height={40} />
                  </IconButton>
                  <Grid item xs={6}>
                    <Box className={classes.infoGroup}>
                      <Skeleton variant="text" width="80%" height={32} />
                      <Skeleton variant="text" width="40%" height={24} />
                      <Skeleton variant="text" width="60%" height={24} />
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Box className={classes.infoGroup}>
                      <Typography id="learner-username" variant="h6" className={classes.infoHeading}>
                        {learnerFirstName} {learnerLastName || ''}
                      </Typography>
                      <Typography variant="overlineSmall" className={classes.identity}>
                        Resident
                      </Typography>
                      <Typography variant="body1" className={classes.infoDetails}>
                        {callType === 'VRS' ? (
                          <Typography id="learner-doc-id" className={classes.groupDetailSubText}>
                            Serial Number: {contactInfoObj?.deviceSerialNumber}
                          </Typography>
                        ) : (
                          <Typography id="learner-doc-id" className={classes.groupDetailSubText}>
                            Doc ID: {isVRSCallToMedical ? 'N/A' : learnerDocId || 'N/A'}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <IconButton>
                    <PhoneForwardedIcon className={classes.phoneForwardedIcon} />
                  </IconButton>

                  <Grid item xs={6}>
                    <Box className={classes.infoGroup}>
                      <Typography variant="h6" id="contact-full-name" className={classes.infoHeading}>
                        {getFullName(contactInfoObj.firstName, contactInfoObj.lastName) || 'N/A'}
                      </Typography>
                      <Typography variant="overlineSmall" className={classes.identity}>
                        Visitor
                      </Typography>
                      <Typography variant="body1" id="learner-contact-details" className={classes.infoDetails}>
                        {contactPhoneNumber} | {contactInfoObj.relationshipType || 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            {voiceCallInsights &&
              voiceCallInsights.length > 0 &&
              (() => {
                const firstInsight = voiceCallInsights.find(
                  (insight) => insight.eventType === VOICE_CALL.VOICE_INSIGHTS.VOICE_MATCH_RESIDENT.value
                );

                return firstInsight ? (
                  <Grid xs={12}>
                    <Typography variant="body1" id="confidence_score_0" className={classes.textGreen}>
                      Voice ID Confidence Rating: {(firstInsight.confidenceScore * 100).toFixed(2)}%
                    </Typography>
                  </Grid>
                ) : null; // Render nothing if no valid insight is found
              })()}

            <Grid xs={12} className={classes.recordingSection}>
              {isLoading ? (
                <>
                  <Grid item xs={12}>
                    <Box className={classes.recordingDetails}>
                      <Skeleton variant="text" width="30%" height={32} />
                      <Box display={'flex'} alignItems={'center'} className={classes.callStateSection}>
                        <Skeleton variant="circle" width={40} height={40} />
                        <Skeleton variant="text" width="40%" height={24} style={{ marginLeft: '10px' }} />
                      </Box>
                      <Skeleton variant="text" width="60%" height={24} />
                      <Skeleton variant="text" width="40%" height={24} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.audioPlayerSection}>
                      <Skeleton variant="rect" width="100%" height={80} />
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Box className={classes.recordingDetails}>
                      <Typography variant="h2">Recording</Typography>
                      <Box display={'flex'} alignItems={'center'} className={classes.callStateSection}>
                        <IconButton>
                          {callState === VOICE_CALL.CALL_HISTORY_STATUS.COMPLETED ? (
                            <>
                              <ArrowOutwardIcon className={classes.arrowOutwordIcon}></ArrowOutwardIcon>
                            </>
                          ) : (
                            <>
                              <CallReceivedIcon className={classes.arrowInwardIcon}></CallReceivedIcon>
                            </>
                          )}
                        </IconButton>
                        <Typography className={VOICE_CALL.getCallStatusClassName(callState, props.classes)}>
                          {'Call '}
                          {VOICE_CALL.capitalizeFirstLetter(callState)}
                        </Typography>
                      </Box>
                      <Typography variant="body2" id="call_duration" className={classes.textGray}>
                        <span className={classes.callDurationText}>Call Duration</span>:{' '}
                        {calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                      </Typography>
                      <Typography variant="body2" id="join_time" className={classes.textGray}>
                        {formatDatetime(endTimeUtc) || 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box id="audio-player" className={classes.audioPlayerSection}>
                      {recordingUrl ? (
                        <>
                          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                            {isRecordingDownloaded && (<><CircularProgress size="30px" /></>)}
                          </Box>
                          <Box className={isPlayerLoaded ? classes.audioPlayer : classes.audioPlayerHidden}>
                            {!isPlayerLoaded && (<Skeleton variant="text" width="100%" height={50} />)}
                            <AudioPlayer
                              elevation={0}
                              variation="primary"
                              download={true}
                              order="standart"
                              preload="auto"
                              getPlayer={(player) => {
                                setIsPlayerLoaded(true);
                              }}
                              rounded={true}
                              src={recordingUrl}
                              useStyles={audioPlayerUseStyles}
                            />
                          </Box>

                        </>
                      ) : (
                        <>
                          <Typography className={classes.noRecordingAvailable} id="noRecordingAvailable">
                            <CustomIcons icon={icons.NoRecordingIcon} className={classes.noRecordingAvailableIcon}></CustomIcons>
                            {isPrivileged ? 'Call Recording Unavailable for Privileged calls' : 'Audio Recording Unavailable'}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>

            {voiceCallInsights?.length > 0 && (
              <Box className={classes.voiceInsightChip}>
                <Typography
                  variant="h3"
                  id="call_insight"
                  data-testid="call-insight-title"
                  className={classes.callInsightTextTitle}
                >
                  Call Insights:{' '}
                </Typography>
                <Box className={classes.voiceInsightChip}>{renderCallInsights(voiceCallInsights, classes, callType)}</Box>
              </Box>
            )}

            {hasTranscript && (
              <Box className={classes.dialogActions} id="transcript-pdf">
                <FlaggedKeywords keywordsData={detectedKeywords} />
                <Typography variant="h3" className={classes.transcriptTitle}>
                  Transcript
                </Typography>
                <Transcript
                  data={transcriptData.transcript}
                  learnerName={learnerFirstName + ' ' + learnerLastName}
                  contactName={(contactInfoObj.firstName || '') + ' ' + (contactInfoObj.lastName || '')}
                  detectedKeywords={detectedKeywords}
                />

                <PDFDownloadLink
                  document={
                    <TranscriptPdf
                      learnerName={learnerFirstName + ' ' + learnerLastName}
                      callerName={(contactInfoObj.firstName || '') + ' ' + (contactInfoObj.lastName || '')}
                      transcriptData={transcriptData}
                      callDuration={calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                      dateTime={formatDatetime(endTimeUtc)}
                      transcriptSummary={transcriptSummary}
                    />
                  }
                  fileName={fileName + '.pdf'}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Loading document...'
                    ) : (
                      <Button variant="outlined" id="downloadTranscript" className={classes.downloadTranscriptBtn}>
                        Download Transcript
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </Box>
            )}

            {hasSummary && (
              <>
                <Grid xs={12} className={classes.transcriptSection}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Summary</Typography>
                    <Box className={classes.summaryBox}>
                      <Typography variant="h6" className={classes.infoHeading}>
                        Main Topics
                      </Typography>
                      <Typography variant="body1" id="transcript-summary" className={classes.summaryText}>
                        {transcriptSummary.main_topics}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};
const renderCallInsights = (voiceCallInsights, classes, callType) => {
  // To ensure uniqueness based on eventType
  const seenEventTypes = new Set();
  const uniqueInsights = [];

  voiceCallInsights.forEach((event) => {
    // If the eventType is not already rendered, add it
    if (!seenEventTypes.has(event.eventType)) {
      seenEventTypes.add(event.eventType);
      uniqueInsights.push(event);
    }
  });

  return uniqueInsights.map((event, index) => {
    let description = '';
    let chipLabel = '';
    let bulletPoints = null;

    // Create a set to track person keys already rendered for the current event type
    const renderedPersonKeys = new Set();

    // Handle different event types and corresponding descriptions
    switch (event.eventType) {
      case 'KEYWORDS_DETECTED':
        chipLabel = 'Keyword Detected';
        description = 'One or more keywords from the facility dictionary were detected during this call.';
        break;

      case 'ACCOUNT_SHARING':
        chipLabel = 'Account Sharing';
        description =
          'These resident voices were detected on the resident’s side of the call, suggesting potential account sharing.';
        bulletPoints = (
          <ul>
            {voiceCallInsights
              .filter((shareEvent) => shareEvent.eventType === 'ACCOUNT_SHARING')
              .map((shareEvent) => {
                // Only render personKey if it hasn't been rendered before for this event type
                if (!renderedPersonKeys.has(`${shareEvent.eventType}-${shareEvent.personKey}`)) {
                  renderedPersonKeys.add(`${shareEvent.eventType}-${shareEvent.personKey}`);
                  return (
                    <li key={shareEvent.personKey}>
                      <strong>
                        {shareEvent.personName || shareEvent.personKey} | Confidence Level{' '}
                        {Math.round(shareEvent.confidenceScore * 100)}%
                      </strong>
                    </li>
                  );
                }
                return null; // Skip rendering if personKey has already been rendered
              })}
          </ul>
        );
        break;

      case 'UNKNOWN_RESIDENT':
        chipLabel = 'Unknown Resident';
        description =
          "An unrecognized voice was detected from the resident's side of the call, indicating a possible unauthorized user of the tablet.";
        break;

      case 'THREE_WAY_CALLING':
        chipLabel = 'Three-Way Calling';
        description =
          'These resident voices were detected on the contact’s side of the call, suggesting they may be facilitating communication between residents.';
        bulletPoints = (
          <ul>
            {voiceCallInsights
              .filter((shareEvent) => shareEvent.eventType === 'THREE_WAY_CALLING')
              .map((shareEvent) => {
                // Only render personKey if it hasn't been rendered before for this event type
                if (!renderedPersonKeys.has(`${shareEvent.eventType}-${shareEvent.personKey}`)) {
                  renderedPersonKeys.add(`${shareEvent.eventType}-${shareEvent.personKey}`);
                  return (
                    <li key={shareEvent.personKey}>
                      <strong>
                        {shareEvent.personName || shareEvent.personKey} | Confidence Level{' '}
                        {Math.round(shareEvent.confidenceScore * 100)}%
                      </strong>
                    </li>
                  );
                }
                return null; // Skip rendering if personKey has already been rendered
              })}
          </ul>
        );
        break;
      case 'VRS_CALL':
        chipLabel = 'Video Relay Call';
        description =
          'This call is identified as a Video Relay Service (VRS) call, where communication is facilitated through a relay interpreter for individuals using sign language.';
        break;

      default:
        break;
    }

    if (!chipLabel && !description) {
      return null;
    }

    return (
      <Box key={index} className={classes.voiceInsightChip}>
        <VoiceInsightChip label={chipLabel} data-testid="voice-insight-chip" className={callType === VOICE_CALL.VOICE_CALL_TYPE.VRS ? 'vrs' : ''} />
        <Typography variant="body1" id="call_insight" data-testid="call-insight-text" className={classes.callInsightText}>
          {description}
        </Typography>
        {bulletPoints}
      </Box>
    );
  });
};

export default withStyles(styles)(CallHistoryDetailsDrawer);
