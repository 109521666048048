export default (theme) => ({
  root: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto'
  },
  addCourseButton: {
    border: `1px ${theme.palette.primary.blue.background} solid`,
    color: theme.palette.primary.blue.background,
    margin: '20px 0 12px 0 !important',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.greyScale.greyBackground,
      borderColor: theme.palette.greyScale.darkSilver,
      color: theme.palette.greyScale.darkSilver
    }
  },
  searchCoursesSection: {
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.secondary.brightBlue.main}`,
    marginTop: '15px',
  },
  searchCourseInput: {
    marginTop: 20,
    marginBottom: 20,
    padding: '10px 14px 10px 14px',

  },
  addCourseRoundButton: {
    width: '40px',
    height: '40px',
    marginLeft: '20px',
    backgroundColor: theme.palette.colors.aquaDeep,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.colors.aquaDeep,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.greyScale.greyBackground,
      color: theme.palette.greyScale.darkSilver
    }
  },
  addIcon: {
    color: 'white',
    fontSize: '24px',
    marginLeft: '5px',
  },
  cancelCourseRoundButton: {
    background: 'white',
    color: '#E32D4E'
  },
  fieldLabel: {
    color: theme.palette.colors.ceruleanMain,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 18,
    fontFamily: 'Rubik',
    marginBottom: theme.spacing(1)
  },
  characterLimit: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'italic',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
});