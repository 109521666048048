import React from 'react';
import { FolderNav as FolderNavComponent } from '@orijinworks/frontend-commons';
import { Box, withStyles } from '@material-ui/core';
import styles from './index.style';
import ManageLocations from '../manage-locations/manageLocations';
import ManageRoleAndPermission from '../manage-roles-permissions/manageRoleAndPermission';
import CheckEvents from '../users/checkEvents';
import { VerifiedUserIcon } from '../../v2/shared/components/MaterialUIIcons';
import { EventNote, LocationOnOutlined, ListAlt } from '@material-ui/icons';
import { ManageAds } from '../../v2/modules/manage-ads';
import ManagePrograms from '../../v2/modules/manage-programs/manage-programs'
import { EntertainmentIcon } from '../icons';
import useUnleashHook from '../../v2/core/unleash/useUnleashHook';
import { withUnleash } from '../../v2/core/unleash';
import { UnleashService } from '../../v2/services';

const SuperAdminModule = ({ classes, unleashProps }) => {
  const adsManagementFlagEnabled = unleashProps.isFlagEnabled();
  const { isFlagEnabled: manageProgramFlagEnabled } = useUnleashHook(UnleashService.FLAGS.MANAGE_PROGRAMS);

  const tabs = [
    {
      label: 'Location Management',
      value: '1',
      component: () => <ManageLocations />,
      Icon: LocationOnOutlined,
    },
    {
      label: 'Roles',
      value: '2',
      component: () => <ManageRoleAndPermission />,
      Icon: VerifiedUserIcon,
    },
    {
      label: 'Check Events',
      value: '3',
      component: () => <CheckEvents />,
      Icon: EventNote,
    },
  ];

  if (adsManagementFlagEnabled) {
    tabs.push({
      label: 'Manage Ads',
      value: '4',
      component: () => <ManageAds />,
      Icon: EntertainmentIcon,
    });
  }

  if (manageProgramFlagEnabled()) {
    tabs.push({
      label: 'Manage Programs',
      value: '5',
      component: () => <ManagePrograms />,
      Icon: ListAlt,
    });
  }

  return (
    <Box className={classes.folderWrapper}>
      <FolderNavComponent defaultActiveTab="1" color="var(--yellow-main)" tabs={tabs} />
    </Box>
  );
};

const MemoizedSuperAdminModule = React.memo(SuperAdminModule, (prevProps, nextProps) => {
  return true;
});
 
export default withUnleash(withStyles(styles)(MemoizedSuperAdminModule), UnleashService.FLAGS.ADS_MANAGEMENT);