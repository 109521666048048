import React, { Suspense } from 'react';
import { withStyles, Grid, Button, Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useManageProgramsHook from './useManageProgramsHook';
import styles from './manage-programs.style';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router-dom';
import { Loader } from '@orijinworks/frontend-commons';
import ProgramList from './components/program-list/program-list';
import AddProgram from './components/add-program/add-program';

const ManagePrograms = ({ classes }) => {
  const { programs, programData, loading, error, showAddProgram, showEditProgram,
    getProgramAndPopulateEditFields, handleAddNew, handleBackToList, handleEditProgram } = useManageProgramsHook();

  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Grid container item component="div" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item container>
            <Loader isLoading={loading} />
          </Grid>

          {!showAddProgram ? (
            <>
              <Grid container item>
                <Typography variant="h1" color="secondary" component="h1">
                  Program Library
                </Typography>
              </Grid>
              <Grid container item justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddNew()}
                  className={classes.addButton}
                >
                  Add New Program
                </Button>
              </Grid>
              <ProgramList
                programs={programs}
                getProgramAndPopulateEditFields={getProgramAndPopulateEditFields}
                classes={classes}
              />
            </>
          ) : (
            <>
              <Grid container item>
                <Breadcrumbs 
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    component="button"
                    variant="body1"
                    onClick={handleBackToList}
                    className={classes.breadcrumbLink}
                  >
                    Program Library
                  </Link>
                  <Typography
                    variant="body1"
                    className={classes.breadcrumbCurrent}
                  >
                    {showEditProgram ? 'Edit Program' : 'Add New Program'}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <AddProgram 
                handleAddNew={handleAddNew}
                handleBackToList={handleBackToList} 
                isEditingMode={showEditProgram} 
                programData={programData} 
                handleEditProgram={handleEditProgram}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Suspense>
  );
};

export default withStyles(styles)(withRouter(ManagePrograms));
