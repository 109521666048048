import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { getCourses } from '../../../../services/program.service';
import { ICONS } from '../../../../../../../constants';

const useAddCourseHook = (formData, handleCourseChange) => {
  const [showSearchSection, setShowSearchSection] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [shouldCallApi, setShouldCallApi] = useState(true);
  const [selectedCourses, setSelectedCourses] = useState(formData?.courses || []);
  const [draggableItems, setDraggableItems] = useState([]);
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const removeCourseIcon = `${ICONS}remove-course.svg`;
  const fetchCourses = async (search) => {
    if (!search?.trim()) {
      setOptions([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await getCourses(search);
      if (response?.status !== "SUCCESS") {
        throw new Error("Failed to fetch courses");
      }

      const formattedOptions = response?.payload?.courses?.map(course => ({
        label: `${course.name}`,
        value: course.id,
        ...course
      })) || [];
      setOptions(formattedOptions);
      setFetchedCourses(formattedOptions);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  };


  // Debounce the API call to avoid too many requests
  const debouncedFetchCourses = debounce(fetchCourses, 500);

  const hasSpecialChars = (str) => /[!@#$%^&*(),.?":{}|<>]/.test(str);

  useEffect(() => {
    if (shouldCallApi && searchValue && searchValue.length > 3 && !hasSpecialChars(searchValue)) {
      debouncedFetchCourses(searchValue);
    } else if (shouldCallApi) {
      setOptions([]);
    }
    // Cleanup
    return () => {
      debouncedFetchCourses.cancel();
    };
  }, [searchValue]);

  const addCourseClick = () => {
    setShowSearchSection(true);
  };

  const hideSearchSection = () => {
    setShowSearchSection(false);
    setSearchValue('');
    setOptions([]);
  };

  const handleSearchChange = (value, type = 'input') => {
    if (type === 'select' && value && value.label) {
      setShouldCallApi(false);
      setSearchValue(value.label);
      setOptions([]);
      debouncedFetchCourses.cancel();
      return;
    }

    if (type === 'input') {
      setShouldCallApi(true);
      setSearchValue(value);
    }
  };

  const addSelectedCourse = () => {
    const selectedOption = fetchedCourses.find(option => option.label === searchValue);
    if (selectedOption && !selectedCourses.some(course => course.value === selectedOption.value)) {
      setSelectedCourses(prevCourses => {
        const isDuplicate = prevCourses.some(course => course.externalId === selectedOption.externalId);
        const newCourses = isDuplicate ? prevCourses : [...prevCourses, selectedOption];
        handleCourseChange?.(newCourses);
        return newCourses;
      });

      setSearchValue('');
      setOptions([]);
    }
  };

  useEffect(() => {
    const items = createDraggableItems(selectedCourses);
    setDraggableItems(items);
  }, [selectedCourses]);

  useEffect(() => {
    if (formData?.courses?.length) {
      setSelectedCourses(formData.courses);
    }
  }, [formData?.courses]);

  const removeCourse = (courseId) => {
    setSelectedCourses(prevCourses => {
      const newCourses = prevCourses.filter(course => (course.id !== courseId && course.value !== courseId));
      handleCourseChange?.(newCourses);
      return newCourses;
    });
  };

  const createDraggableItems = (courses) => {
    const itemsWithOrder = courses.map((course, index) => {
      const courseId = course.id || course.value;
      return {
        id: courseId,
        title: course.name || course.label || '',
        subtitle: course.externalId || '',
        displayOrder: course.displayOrder !== undefined ? course.displayOrder : index,
        actionElement: (
          <IconButton onClick={() => removeCourse(courseId)}>
            <img src={removeCourseIcon} alt="Remove course" />
          </IconButton>
        )
      };
    });

    return itemsWithOrder.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  return {
    addCourseClick,
    showSearchSection,
    hideSearchSection,
    searchValue,
    handleSearchChange,
    isLoading,
    options,
    addSelectedCourse,
    draggableItems
  };
};

export default useAddCourseHook;
