import React from 'react';
import styles from './group-schedule-settings-list.style';
import { Box, withStyles, Grid, Snackbar } from '@material-ui/core';
import { Loader } from '@orijinworks/frontend-commons';
import ScheduleAccordian from '../schedule-accordian/schedule-accordian';
import MuiAlert from '@material-ui/lab/Alert';

const GroupScheduleSettingsList = ({ classes, groupScheduleSettings, loading, showSnackBar, onDeleteSchedule, error, setShowSnackBar, maxMinutesLimit }) => {

  if (loading) return <Loader />;

  return (
    <Box display= "flex" flexDirection="column" width="100%">

      <Snackbar open={showSnackBar} autoHideDuration={3000} onClose={() => { setShowSnackBar(false) }}>
        <MuiAlert elevation={6} variant="filled" severity={error ? 'error' : 'success'}>
         {error? error: 'Schedule deleted successfully'}
        </MuiAlert>
      </Snackbar>
      <Box className={classes.root}>
        {groupScheduleSettings.map((schedule) => (
          <Grid item key={schedule.id} xs={12} className={classes.row}>
            <ScheduleAccordian scheduleData={schedule} onDeleteSchedule={onDeleteSchedule} maxMinutesLimit={maxMinutesLimit} />
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(GroupScheduleSettingsList);
