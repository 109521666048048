export const VOICE_CALL = {
  TABS: {
    ALL_CONTACTS: 'ALL_CONTACTS',
    PENDING_CONTACTS: 'PENDING_CONTACTS',
    LIVE_CALLS: 'LIVE_CALLS',
    CALL_HISTORY: 'CALL_HISTORY',
    SETTINGS: 'SETTINGS',
    RESIDENT_MANAGEMENT: 'RESIDENT_MANAGEMENT',
  },
  TABS_MAPPING: {
    ALL_CONTACTS: 'All Contacts',
    PENDING_CONTACTS: 'Pending Contacts',
    LIVE_CALLS: 'Live Calls',
    CALL_HISTORY: 'Facility Call History',
    RESIDENT_MANAGEMENT: 'Resident Management',
    SETTINGS: 'Communications Settings'
  },
  STATUS: {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    BLOCKED: 'BLOCKED',
    INACTIVE: 'INACTIVE',
  },
  CALL_HISTORY_STATUS: {
    COMPLETED: 'COMPLETED',
    MISSED: 'MISSED',
    TERMINATED: 'TERMINATED',
    CANCELLED: 'CANCELLED',
    DECLINED: 'DECLINED',
  },
  TRANSCRIPT_DISPLAY_NAMES: {
    learner: 'Caller',
    pstn: 'Contact',
    admin: 'Admin',
  },

  MAX_NO_OF_CONTACTS_PER_LEARNER: 20,
  MIN_NO_OF_CONTACTS_PER_LEARNER: 0,
  MIN_NO_OF_MINUTES_PER_CALL: 0,
  MAX_NO_OF_MINUTES_PER_CALL: 120,
  MIN_NO_OF_MINUTES_PER_DAY_PER_LEARNER: 0,
  MAX_NO_OF_MINUTES_PER_DAY_PER_LEARNER: 600,
  MAX_NO_SLOTS_IN_DAY: 4,
  MIN_SLOT_DURATION: 30,
  MAX_MINUTES_ALLOWED_PER_CALL_ATTRIBUTE_KEY: 'Call_Duration',
  MAX_MINUTES_ALLOWED_PER_DAY_PER_LEARNER_ATTRIBUTE_KEY: 'MaxMinPerDayPerLearner',
  ALLOW_CONTACT_REQUESTS_ATTRIBUTE_KEY: 'Voice_Contact_Request_Allowed',
  ADMIN_CAN_APPROVE_CONTACTS_ATTRIBUTE_KEY: 'Admin_Contact_Approval',
  MAX_CONTACTS_PER_LEARNER_ENABLED_LIMIT_ATTRIBUTE_KEY: 'Voice_Contact_Limit_Enabled',
  MAX_CONTACTS_PER_LEARNER_LIMIT_ATTRIBUTE_KEY: 'Voice_Contact_Limit',
  GROUP_SCHEDULE_DEFAULT_ATTRIBUTE_KEY: 'GROUP_SCHEDULE_DEFAULT',
  VOICE_ID_REGISTRATION_ATTRIBUTE_KEY: 'Voice_ID_Registration',
  DAY_OF_WEEK_MAP: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  },

  getRelationshipTypes: () => {
    return [
      { value: 'FRIEND', id: 'friend', backendValue: 'FRIEND' },
      { value: 'FAMILY', id: 'family', backendValue: 'FAMILY' },
      { value: 'GENERAL CONTACT', id: 'general-contact', backendValue: 'GENERAL_CONTACT' },
      { value: 'ATTORNEY', id: 'attorney', backendValue: 'ATTORNEY' },
      { value: 'PROBATION / PAROLE OFFICER', id: 'probation-officer', backendValue: 'PROBATION_PAROLE_OFFICER' },
      { value: 'MEDICAL', id: 'medical', backendValue: 'MEDICAL' },
      { value: 'GENERAL PRIVILEGED CONTACT', id: 'general-privileged-contact', backendValue: 'GENERAL_PRIVILEGED_CONTACT' },
    ];
  },

  getPrivilegeRelationShipTypes: () => {
    return ['ATTORNEY', 'MEDICAL', 'GENERAL PRIVILEGED CONTACT'];
  },

  downloadFileFromUrl : async (url,fileName) => {
    try {
      const response = await fetch(url);
      const extension = VOICE_CALL.getFileExtensionFromSignedUrl(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${fileName}.${extension}`;
      document.body.appendChild(a);
      setTimeout(() => {
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      }, 100); // Delay for security policies
    } catch (error) {
      console.error('Download error:', error);
    }
  },

  getFileExtensionFromSignedUrl(url) {
    try {
      const parsedUrl = new URL(url);
      const path = parsedUrl.pathname;
      const fileName = path.split('/').pop();
      
      // Extract the extension
      const extension = fileName.split('.').pop();
      
      // Return the extension only if it's valid
      return extension && extension !== fileName ? extension : null;
    } catch (error) {
      console.error("Error extracting file extension:", error);
      return null;
    }
  },
  getLanguages() {
    return [
      { value: 'en-US', label: 'English' },
      { value: 'es-US', label: 'Spanish' },
      // Add more languages as needed
    ];
  },
  isValidUSPhoneNumber: (phoneNumber) => {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    const digits = phoneNumber.replace(/\D/g, '');
    return phoneRe.test(digits);
  },

  capitalizeFirstLetter : (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
  },
  getCallStatusClassName : (callState, classes) => {
    switch (callState) {
      case 'COMPLETED':
        return classes.success;
      case 'MISSED':
        return classes.warning;
      case 'TERMINATED':
        return classes.danger;
      default:
        return '';
    }
  },

  SOCKET_EVENTS: {
    VOICE_KEYWORD_DETECTED: 'voice_keyword_detected',
    VOICE_INSIGHTS_EVENT: 'voice_insights_event',
    VOICE_MEETING_STATE_CHANGED: 'voice_meeting_state_changed',
  },

  VOICE_INSIGHTS: {
    KEYWORDS_DETECTED: { value: 'KEYWORDS_DETECTED', label: 'Keyword Detected' },
    THREE_WAY_CALLING: { value: 'THREE_WAY_CALLING', label: 'Three Way Calling' },
    UNKNOWN_RESIDENT: { value: 'UNKNOWN_RESIDENT', label: 'Unknown Resident' },
    ACCOUNT_SHARING: { value: 'ACCOUNT_SHARING', label: 'Account Sharing' },
    VOICE_MATCH_RESIDENT: { value: 'VOICE_MATCH_RESIDENT', label: 'Voice Match Resident' },
    MULTIPLE_SPEAKERS: { value: 'MULTIPLE_SPEAKERS', label: 'Multiple Speakers' },
    VRS_CALL: { value: 'VRS_CALL', label: 'Video Relay Call' },
  },

  PERSON_ATTRIBUTE: {
    A11Y_VRS_ENABLED: 'A11Y_VRS_ENABLED',
  },

  VOICE_CALL_TYPE : {
    PSTN: 'PSTN',
    VRS: 'VRS',
  }

};
