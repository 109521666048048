import React, { useEffect } from 'react';
import styles from './group-schedule-settings.style';
import { Box, withStyles, Grid, Divider } from '@material-ui/core';
import { Autocomplete, Button, Typography } from '@orijinworks/frontend-commons';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import GroupScheduleSettingsList from './../group-schedule-settings-list/group-schedule-settings-list';
import CreateScheduleModal from '../create-schedule-modal/create-schedule-modal';
import useGroupScheduleSettingsHook from './useGroupScheduleSettingsHook';
const GroupScheduleSettings = ({ classes, locationId }) => {
  const {
    modalRef,
    handleCreateScheduleModalOpen,
    handleCreateScheduleModalClose,
    isNewScheduleAdded,
    setIsNewScheduleAdded,
    groupScheduleSettings,
    loading,
    showSnackBar,
    onDeleteSchedule,
    error,
    allSchedulesOptions,
    setShowSnackBar,
    handleDefaultScheduleChange,
    selectedSchedule,
    maxMinutesLimit
  } = useGroupScheduleSettingsHook({
    locationId,
  });

  return (
    <>
      <Grid item xs={12}>
        <Box id="header" className={classes.header}>
          <GroupsIcon className={classes.icon} />
          <Typography id="group-schedule-settings-heading" className={classes.subHeading} variant="h2">
            Group Schedule Settings
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.dividerRow}>
          <Divider id="divider" className={classes.divider}></Divider>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Box id="new-learner-schedule" className={classes.stableTableCellOne}>
          <Typography id="default-schedule-label" variant="body1" className={classes.label}>
            Default Schedule
          </Typography>
          <Typography id="default-schedule-description" variant="body2" className={classes.subText}>
            Determine the schedule for newly added residents. Any resident removed from a schedule will also be assigned
            to the default schedule.
          </Typography>
        </Box>
        <Box id="limit-contact-number" className={classes.stableTableCellTwo}>
          <Autocomplete
            label="Select default schedule"
            options={allSchedulesOptions}
            onChange={(event, value) => handleDefaultScheduleChange(event, value)}
            value={selectedSchedule}
            getOptionLabel={(option) => option.label}
            disableClearable={true}
            freeSolo={false}
            testId="group-schedule-settings"
            renderOption={(props, option, { selected }) => (
              <li {...props}>
            <span className={selected ? classes.boldText : ''}>
              {option.label}
            </span>
              </li>
            )}
            className={classes.scheduleDropdown}
          ></Autocomplete>
          <Typography id="new-residents-info" className={classes.infoText}>
            <InfoIcon width={10} height={10} />
            New residents will not be able to make calls until added to a schedule.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <Box id="schedules" className={classes.stableTableCellOne}>
          <Typography id="schedules-label" className={classes.scheduleLabel}>
            Schedules
          </Typography>
          {
            allSchedulesOptions && allSchedulesOptions.length === 1 ? (<>
              <Typography id="no-schedule-info" variant="body2" className={classes.subText}>
                All residents will remain on no schedule until at least one schedule is created by the facility. Please set up a
                schedule to ensure proper management and access for all residents.
              </Typography>
            </>
            ) : null
          }
        </Box>
        <Box id="create-new-schedule-box" className={classes.createNewScheuleBox}>
          <Button
            variant="contained"
            id="create-new-schedule-btn"
            className={classes.createNewScheuleBtn}
            onClick={handleCreateScheduleModalOpen}
          >
            + &nbsp; Create New Schedule
          </Button>
        </Box>
      </Grid>
      <CreateScheduleModal
        open={false}
        onClose={handleCreateScheduleModalClose}
        ref={modalRef}
        locationId={locationId}
        isNewScheduleAdded={isNewScheduleAdded}
        setIsNewScheduleAdded={setIsNewScheduleAdded}
      />
      <GroupScheduleSettingsList
        locationId={locationId}
        isNewScheduleAdded={isNewScheduleAdded}
        groupScheduleSettings={groupScheduleSettings}
        loading={loading}
        showSnackBar={showSnackBar}
        onDeleteSchedule={onDeleteSchedule}
        error={error}
        setShowSnackBar={setShowSnackBar}
        maxMinutesLimit={maxMinutesLimit}
      />
    </>
  );
};

export default withStyles(styles)(GroupScheduleSettings);
