import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Zoom, Box, Button, withStyles } from '@material-ui/core';
import styles from './program-list.style';
import Datatable, { MTableToolbar } from 'material-table';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Hoverable from '../../../../../components/hoverable/index';

const ROWS_PER_PAGE = 20;

const ProgramList = ({ programs, getProgramAndPopulateEditFields, classes }) => {
  const columns = [
    {
      field: 'name',
      title: 'Program Name',
      filtering: false,
      render: rowData => <Hoverable title={rowData.name}>{rowData.name}</Hoverable>
    },
    {
      field: 'updatedBy',
      title: 'Updated By',
      filtering: false,
      render: rowData => <Hoverable title={rowData.updatedBy}>{rowData.updatedBy}</Hoverable>
    },
    {
      field: 'updatedDate',
      title: 'Updated Date',
      filtering: false,
      render: rowData => {
        const formattedDate = rowData.updatedDate ? new Date(rowData.updatedDate).toISOString().split('T')[0] : '';
        return <Hoverable title={formattedDate}>{formattedDate}</Hoverable>;
      }
    },
    {
      field: 'actions',
      title: 'Actions',
      filtering: false,
      sorting: false,
      render: rowData => (
        <Box className={classes.actionsContainer}>
          <Hoverable title="Click to edit">
            <Button
              id={`program-btn-${rowData.id}`}
              variant="contained"
              color="primary"
              onClick={(e) => {
                getProgramAndPopulateEditFields(e, rowData.id);
              }}
            >
              Edit
            </Button>
          </Hoverable>
        </Box>
      )
    }
  ];

  return (
    <Grid item container>
      <Zoom className={classes.zoomContainer} in unmountOnExit>
        <div className={classes.container}>
          <Datatable
            title=""
            data={programs}
            columns={columns}
            className={classes.tableHeader}
            icons={{
              Search: SearchIcon,
              ResetSearch: CloseIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon,
              Filter: FilterListIcon,
              SortArrow: ArrowDownwardIcon,
              Export: SaveAltIcon,
              Edit: EditIcon
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                </div>
              )
            }}
            options={{
              search: false,
              sorting: true,
              filtering: true,
              pageSize: ROWS_PER_PAGE,
              pageSizeOptions: [20, 50, 100],
              actionsColumnIndex: -1,
              maxBodyHeight: 'auto',
              minBodyHeight: 'auto'
            }}
          />
        </div>
      </Zoom>
    </Grid>
  );
};

ProgramList.propTypes = {
  programs: PropTypes.array.isRequired,
  getProgramAndPopulateEditFields: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProgramList);